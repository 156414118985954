.tooltip-button-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 80px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 55%;
  left: -100px;
  margin-top: -10px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-text::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -5px;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}

.tooltip-button-wrapper:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-button {
  background-color: transparent;
  border: none;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  outline: none;
  box-shadow: none;
  -webkit-tap-highlight-color: transparent;
}

.tooltip-button:hover {
  background-color: transparent;
}

.tooltip-button:active {
  background-color: transparent;
}

.tooltip-button:focus {
  background-color: transparent;
  outline: none;
}

.icon-image {
  width: 100%;
  height: 100%;
  user-select: none;
  pointer-events: none;
}

.tooltip-wrapper {
  position: absolute;
  top: -10px;
  right: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.info-modal {
  background-color: #34495e;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 80%;
  text-align: left;
  color: #ecf0f1;
  overflow-y: auto;
  max-height: 80%;
  font-size: 12px;
}

.info-modal h2, .info-modal h3 {
  margin-top: 0;
  font-size: 14px;
  color: #ecf0f1;
}

.info-modal p {
  margin-bottom: 10px;
  font-size: 12px;
  color: #ecf0f1;
}

.link, .wallet-link {
  color: #1e90ff;
  text-decoration: none;
}

.link:hover, .wallet-link:hover {
  text-decoration: underline;
  color: #1e90ff;
}

.evm-warning {
  color: #FF4C4C !important;
}

.special-thanks {
  color: #ecf0f1;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 10px;
}

.special-thanks-logo {
  width: 30px;
  height: 30px;
}

.collapsible-header {
  cursor: pointer;
  user-select: none;
  color: #ecf0f1;
}

.exchange-list {
  list-style-type: none;
  padding-left: 0;
}

.exchange-item {
  margin-bottom: 15px;
}

.exchange-header {
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #ecf0f1;
}

.wallet-list {
  list-style-type: none;
  padding-left: 20px;
}

.wallet-item {
  margin-bottom: 5px;
  color: #ecf0f1;
}

.dash {
  font-weight: bold;
  margin-right: 5px;
  color: #ecf0f1;
}

.close-button {
  background-color: #2980b9;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  display: block;
  width: 100%;
}

.close-button:hover {
  background-color: #3498db;
}

.exchange-logo {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  vertical-align: middle;
}
