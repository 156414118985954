.bankroll-summary {
  margin: 0 auto;
  width: 94%;
  padding: 10px;
  background-color: #3b5998;
  border-radius: 5px;
  color: #ecf0f1;
  text-align: left;
}

.bankroll-summary h3 {
  margin-bottom: 5px;
  font-family: 'Arial', sans-serif;
  text-align: left;
}

.bankroll-summary p {
  font-family: 'Arial', sans-serif;
  margin: 5px 0;
  text-align: left;
}

.bankroll-summary ul {
  padding-left: 20px;
  list-style-position: inside;
  text-align: left;
}

.bankroll-summary li {
  font-family: 'Courier New', Courier, monospace;
  margin-bottom: 5px;
  text-align: left;
}

.bankroll-summary hr {
  border-top: 1px solid #ecf0f1;
  margin: 10px 0;
}

.bankroll-summary .monospace {
  font-family: 'Courier New', Courier, monospace;
}

.toggle-switch-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-right: 10px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #3498db;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  font-family: 'Arial', sans-serif;
  color: #ecf0f1;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sei-logo {
  width: 15px; /* Adjust size as needed */
  height: 15px;
  margin-left: 5px;
  vertical-align: middle;
}


.token-logo {
  width: 15px; /* Adjust size as needed */
  height: 15px;
  margin-left: 5px; /* Space between text and logo */
  vertical-align: middle;
}
