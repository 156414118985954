.table-container {
  width: 94%; /* 100% - 3% margin on each side */
  margin: 0 auto;
  padding-bottom: 40px; /* Add padding at the bottom to make room for the scrollbar */
}

.table-scroll-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  background-color: #2c3e50; /* Background color for the scroll container */
  padding: 10px 0; /* Add padding only to the top and bottom for better visibility */
}

/* Custom scrollbar styling */
.table-scroll-container::-webkit-scrollbar {
  height: 12px; /* Increase height for better visibility */
}

.table-scroll-container::-webkit-scrollbar-thumb {
  background-color: #3498db; /* Match the button color */
  border-radius: 6px; /* Rounded corners for the scrollbar thumb */
}

.table-scroll-container::-webkit-scrollbar-track {
  background-color: #2c3e50; /* Background color for the scrollbar track */
}

.results-table {
  width: 100%;
  min-width: 800px; /* Set a minimum width for the table */
  margin: 0 auto; /* Adjust margin to remove excess space */
  border-collapse: collapse;
  font-family: 'Arial', sans-serif; /* Change default font to Arial */
  font-size: 10pt; /* Set font size to 10pt */
  background-color: #2c3e50;
  table-layout: auto;
}

.results-table th,
.results-table td {
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 10pt; /* Set font size to 10pt */
  color: #ecf0f1;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap; /* Prevent text from wrapping */
}

.results-table .monospace {
  font-family: 'Courier New', Courier, monospace; /* Set font to Courier New for specific columns */
}

.results-table th {
  background-color: #34495e; /* Darker header background */
  color: #ecf0f1; /* Lighter header text color */
  cursor: pointer;
}

.results-table th .header-content {
  display: flex;
  align-items: center;
  justify-content: center; /* Center the text content */
  width: 100%;
  box-sizing: border-box;
}

.results-table th .header-text {
  flex-grow: 1;
  text-align: center;
  word-wrap: break-word; /* Allow text to wrap within the cell */
}

.results-table th .sort-arrows {
  margin-left: 5px;
  font-size: 1em; /* Adjust the font size for sort icons */
  visibility: visible; /* Ensure visibility */
}

.results-table td {
  background-color: #2c3e50; /* Match table background */
}

.results-table tr:nth-child(even) td {
  background-color: #34495e; /* Slightly darker for even rows */
}

.results-table a {
  color: #1e90ff; /* Brighter blue for links */
  text-decoration: none; /* Remove underline */
}

.results-table a:hover {
  color: #1e80e5; /* Darker blue on hover */
}

.copy-button {
  background-color: transparent;
  color: #1e90ff; /* Brighter copy button color */
  border: none;
  padding: 5px;
  font-size: 15px;
  cursor: pointer;
  margin-left: 5px;
  margin-top: -3px;
  width: 24px; /* Set a fixed width */
  text-align: center; /* Center the content */
}

.copy-button:hover {
  color: #1e80e5; /* Darker hover color for copy button */
}

.copy-message {
  font-weight: bold;
  color: green;
  margin-top: 5px;
  animation: fade-in-out 2s;
}

@keyframes fade-in-out {
  0% { opacity: 0; }
  20% { opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

.cell-container {
  display: flex;
  flex-direction: column; /* Change to column to allow new line */
  align-items: center;
  justify-content: center; /* Center the content horizontally */
}

.address-row {
  display: flex;
  align-items: center;
}

[class^="address-row-column-"] {
  /* Add any specific styling for address-row-column classes */
  margin-bottom: 10px; /* Add some space between each row-column */
}

.exchange-logo {
  width: 15px;
  height: 15px;
  margin-left: 5px; /* Add margin to the right of the logo */
  vertical-align: middle;
}

.summary {
  margin-top: 15px;
  text-align: left;
  font-family: 'Arial', sans-serif; /* Change default font to Arial */
  color: #ecf0f1;
  padding-right: 20px;
  width: 100%; /* Ensure summary takes the same width as table */
}

.summary h3 {
  margin-bottom: 5px;
}

.summary ul {
  padding-left: 20px;
}

.duplicate-section {
  background-color: #3b5998; /* Different background color for duplicate section */
  padding: 10px;
  border-radius: 5px;
}

.duplicate-section li {
  margin-bottom: 10px;
}

.wallet-list {
  display: block; /* Display as block element */
  margin-left: 20px; /* Indent the list of wallets */
}

/* Media queries for responsive text size */
@media (max-width: 1200px) {
  .results-table th,
  .results-table td {
    font-size: 9pt;
  }
  .results-table th .sort-arrows {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .results-table th,
  .results-table td {
    font-size: 8pt;
  }
  .results-table th .sort-arrows {
    font-size: 10px;
  }
}

@media (max-width: 768px) {
  .results-table th,
  .results-table td {
    font-size: 7pt;
  }
  .results-table th .sort-arrows {
    font-size: 8px;
  }
}

@media (max-width: 576px) {
  .results-table th,
  .results-table td {
    font-size: 6pt;
  }
  .results-table th .sort-arrows {
    font-size: 6px;
  }
}

.monospace {
  font-family: 'Courier New', Courier, monospace;
}

.results-table th .sort-arrows {
  display: flex;
  flex-direction: column; /* Stack arrows vertically */
  margin-left: 5px;
  font-size: 1em; /* Adjust the font size for sort icons */
  visibility: visible; /* Ensure visibility */
  align-items: center; /* Center arrows horizontally */
}
