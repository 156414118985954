body {
  font-family: 'Arial', sans-serif;
  background-color: #2c3e50;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  padding: 10px;
  position: relative;
  overflow-x: hidden; /* Add this to prevent horizontal overflow */
}

h1 {
  color: #ecf0f1;
  margin-bottom: 15px;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.header-logo {
  width: 40px;
  height: 40px;
   margin-bottom: 15px;
}

.nav-container {
  margin-top: 5px;
  text-align: center;
}

.nav-container a {
  color: #1e90ff;
  text-decoration: none;
  font-size: 14px;
  font-weight: bold;
}

.nav-container a:hover {
  color: #2980b9;
  text-decoration: underline;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.textarea-container {
  position: relative;
  width: 60%;
  margin-bottom: 10px;
}

textarea {
  width: 100%;
  height: 80px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.token-checkbox-container {
  width: 60%;
  color: #ecf0f1;
  text-align: left;
  cursor: pointer;
  margin-bottom: 10px;
}

.token-checkbox-container label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
}

.token-options {
  display: none;
  margin-top: 8px;
}

.token-options.expanded {
  display: block;
}

.arrow {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  font-size: 0.9em;
  color: #ecf0f1;
}

.arrow.up::after {
  content: '🔼';
}

.arrow.down::after {
  content: '🔽';
}

.checkbox-container {
  display: flex;
  justify-content: flex-start; /* Align checkboxes to the start (left) */
  width: 60%;
  margin-bottom: 10px;
  position: relative; /* Needed for positioning the timeout message */
}

.timeout-message-container {
  width: 60%;
  margin-top: -10px;
  text-align: left;
  margin-left: 50px;
}

.checkbox-container label {
  color: #ecf0f1;
  margin-left: 10px;
}

.results-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
  width: 100%; /* Ensure it takes full width */
  overflow-x: hidden; /* Prevent horizontal overflow */
}

button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 8px;
}

button:hover {
  background-color: #2980b9;
}

.info-container {
  height: 40px; /* Adjust this to make space for error and ETA text */
  margin-bottom: 10px;
  position: relative;
}

.error {
  color: red;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap; /* Prevent text wrapping */
}

.info-text {
  color: #ecf0f1;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  white-space: nowrap; /* Prevent text wrapping */
  font-size: 14px; /* Adjust font size if necessary */
}

.wallet-info {
  display: flex;
  align-items: center;
}

.submit-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tooltip-container {
  position: absolute;
  top: 10px;
  right: 10px;
}

.eta-text {
  color: #ecf0f1;
}

.toggle-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.toggle-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.toggle-switch {
  position: relative;
  width: 50px;
  height: 25px;
  background-color: #ddd;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.toggle-switch::before {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 50%;
  transition: transform 0.2s;
}

.toggle-switch.on {
  background-color: #3498db;
}

.toggle-switch.on::before {
  transform: translateX(25px);
}

.toggle-label {
  font-size: 12px;
  color: #ecf0f1;
  user-select: none;
}
